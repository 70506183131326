import { XXSmall } from "@/styles/Type"
import clsx from "clsx"
import { HTMLAttributes, ReactNode } from "react"

const themes = {
  default: "bg-offblack text-white",
  draft: "bg-warning text-offblack",
  custom: "",
  gold: "bg-gold text-offblack",
}
type MiniPillProps = {
  theme?: keyof typeof themes
  children: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const MiniPill = ({
  theme = "default",
  className = "",
  children,
}: MiniPillProps) => {
  return (
    <XXSmall
      as="div"
      className={clsx(
        "font-medium rounded-full px-2 inline-block",
        className,
        themes?.[theme] || themes.default
      )}
    >
      {children}
    </XXSmall>
  )
}
export default MiniPill
